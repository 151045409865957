<template>
  <transition name="fade" appear>
    <div id="moreBuilding">
      <div class="box">
        <el-input
          type="textarea"
          autosize
          resize="none"
          placeholder="请输入文字"
          v-model="queryInfo.content"
          class="input"
        >
        </el-input>
        <div class="upload flex flex-wrap">
          <el-upload
            :action="$uploadURL"
            list-type="picture-card"
            :on-preview="previewImg"
            :on-remove="removeImg"
            :before-upload="checkFormat"
            :on-success="uploadImg"
            :limit="fileLimit"
            :multiple="true"
            :on-exceed="showTips"
            style="  text-align: left;"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="isPreview">
            <el-image width="100%" :src="previewItem" alt="" />
          </el-dialog>
        </div>
        <div class="buttonGroup flex">
          <el-button class="button button1" @click="backPrev">取消</el-button>
          <el-button class="button button2" type="warning" @click="jumpDetail"
            >发布</el-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        content: "",
        fileList: [],
        memberCode: "",
      },
      isPreview: false,
      // 文件上传数量限制
      fileLimit: 9,
      // 文件类型
      fileType: '',
      // 预览的图片
      previewItem: '',
      list: '',
      memberCode: "",
    }
  },

  methods: {
    //返回上一页
    backPrev(){
      this.$router.go(-1)
    },
    // 判断上传文件类型
    checkFormat(file) {
      const tempList = file.name.split('.')
      const fileType = tempList[tempList.length - 1]
      const imgFile = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // const videoFile = ['mp4', 'rmvb', 'avi', 'flv', 'm2v', 'mkv', 'wmv', 'mp3', 'wav']
      if (!this.fileType) {
        if (imgFile.indexOf(fileType) > -1) {
          this.fileType = 'img'
          this.fileLimit = 9
        } else {
          this.$message({
            message: '请上传图片!',
            type: 'warning',
            offset:'400'
          })
          return false
        }
      } else if (this.fileType === 'img') {
        if (imgFile.indexOf(fileType) === -1) {
          this.$message({
            message: '请上传图片!',
            type: 'warning',
            offset:'400'
          })
          return false
        }
      }
      console.log("----",this.queryInfo.fileList)
    },
    // 格式化上传图片源数据类型
    formatUpPic(fileList) {
      this.queryInfo.fileList = fileList.map((v) => {
        return {
          name: v.name,
          url: v.response ? v.response.data.url : v.url,
        };
      });
    },

   showTips(){     
          // this.$message.warning("最多上传9张图片！");
           this.$message({
            message: "最多上传9张图片！",
            type: "warning",
            offset:'400'
          });
    },
    // 上传图片
    uploadImg(res, file, fileList) {
      this.formatUpPic(fileList);
    },
    // 预览图片
    previewImg(file) {
      this.isPreview = true;
      this.previewItem = file.url;
    },

    // 删除图片
    removeImg(res, file, fileList) {
      const index = this.queryInfo.fileList.findIndex((v) => {
        return v.name === res.name;
      });
      this.queryInfo.fileList.splice(index, 1);
    },
    jumpDetail() {
      var data = {};
      data.babyEnclosureList = this.queryInfo.fileList;
      data.content = this.queryInfo.content;
      data.memberCode = this.memberCode;
      if ( data.babyEnclosureList.length>0 ) {
         this.$api.partyBuild.getMore(data).then((res) => {
        if (res.data.code === 200) {
          // this.$message.success("已发布，请等待审核！");
           this.$message({
            message: "已发布，请等待审核！",
            type: "success",
            offset:'400'
          });
          this.$router.push("/partyBuilding");
        }
      });
      } else {
        //  this.$message.warning("请上传图片！");
         this.$message({
            message: "请上传图片！",
            type: "warning",
            offset:'400'
          });
      }
     
    }
  },

  beforeCreate() {
    this.$nextTick(() => {
      // 修改背景色
      document.querySelector("#index").setAttribute("style", "background:#eeeeee");
    });
  },
  beforeDestroy() {
    document.querySelector("#index").setAttribute("style", "background:#ffffff");
  },
  created() {
      this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode;
  }

}
</script>

<style lang="less" scoped>
#moreBuilding{
  padding: 30px 0;
}
.box {
  margin: 0 auto;
  width: 954px;
  padding: 70px 70px 95px;
  background: white;
  .input {
    margin-bottom: 30px;
    font-size: 24px;
    /deep/.el-textarea__inner {
      border: none;
      border-radius: 0;
      background: white;
    }
  }
}
.buttonGroup {
  margin-top: 84px;
  .button {
    width: 157px;
    height: 59px;
    margin-right: 153px;
    font-size: 24px;
  }
  .button1 {
    border: 2px solid #e6a23c;
    color: #f3ae28;
  }
}
</style>